#root {
    overflow-y: scroll;
}
body {
    height: 100%;
    min-height: 100%;
    font-family: "Google Sans", sans-serif !important;

}
html {
    scroll-behavior: auto!important;

}
.home-main {
    overflow-y: scroll;
    color: white;
}
.image-banner {
    width: 100%;
    padding-bottom: 15em;
    padding-left: 3em;
    position: relative;
    margin-bottom: 0;
}
.image-video {
    position: absolute;
    left: 0;
    z-index: -2;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
    filter:brightness(45%);
    background-color:black;

}

.image-banner h1 {
    padding-top: 2em;
    color: white;
    font-weight: bolder;
    font-size: 5em;
}
.image-banner p {
    font-size: 1.5em;
    width: 50%;
    margin-top: 1em;
    margin-bottom: 1em;
}
.image-banner button {
    font-size: 16px;
    padding: 0.75em
}

.image-banner a {
    font-size: 16px;
    color: white;
}




.image-banner a:hover {
    color: red;
}
.image-banner-content em {
    color: #E11F26;
    font-weight: bolder;
}

.image-video-overlay {

}


.home-cta {
}

.home-cta button {
    margin-left: 0;
}

.home-instructions {
    color:black;
    margin-top: 2em;
}

.home-ins-title {
    font-weight: bolder;
    text-align: center;
    font-size: 1.8em;
}
.home-ins-subtitle {
    text-align: center;
}

.home-ins-functionality {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.home-ins-functionality p {
    font-weight: bold;
    margin-top: 1em;
    text-align: center;
}
.home-ins-functionality img {
    max-width: 200px;
}



.home-how-it-works {
    height: 100%;
    padding-left: 3em;
    padding-top: 3em;
    color: black;
    width: 100%;
    display: flex; flex-direction: row; justify-content: space-around;


}
.hhiw-img {
 max-width: 200px;
}
.hhiw-left {
    flex: 0 0 50%;
}
.home-how-it-works h1 {
    font-size: 1.5em;
    font-weight: bolder;
    margin-top: 1em;
    margin-bottom: 1em;
}
.home-how-it-works p {
}

.hhiw-do {
    border-radius: 2em;
    padding: 1em;
}

.hhiw-do-columns {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}
.hhiw-do-columns ul {
    margin-left: 0;
    margin-top: 1em;
    padding-right: 1.2em;
}
.hhiw-access {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2em;
}

.hhiw-access a {
    flex: 0 0 33%;
}

.hhiw-access img {
    max-height: 75px;
}

.hhiw-access button {

    font-size: 16px;
    padding: 1em;
}
.home-todo-banner {
    background-color: darkred;
    margin-top: 2em;
    color: white !important;
    padding-top: 2em;
    padding-bottom: 1.5em;
}
.home-todo-banner h1 {
    color: white;
    margin-bottom: 1em;
}

.home-step {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: black !important;
    margin-top: 2em;
}

.home-step p {
}

.home-step > * {
    flex: 0 0 49%;
    padding-left: 2em;
    padding-top: 2em;
}

.home-step img {
    height: 100%;
    width: 100%;
    margin:auto;
    text-align: center;
    -webkit-box-shadow: 5px 6px 5px 0px rgba(161,161,161,1);
    -moz-box-shadow: 5px 6px 5px 0px rgba(161,161,161,1);
    box-shadow: 5px 6px 5px 0px rgba(161,161,161,1);
}

.home-step-number {
    width: max-content;
    font-weight: bolder;
}

.hhiw-img-lg {
    max-width: 85%;
    padding-left: 4em;
}
.home-contact-form {
    padding-left: 2em;
    color: black !important;
    margin-top: 3em;
    margin-bottom: 5em;
}
.home-contact-form h1 {
    font-weight: bolder;
}
.hcf-form {
    width: 60%;
}
.hcf-form button {
    padding: 1em;
    margin-left: 0;
}
.hcf-form-row {
    display: flex;
    flex-direction: row;
}
.hcf-form-row input {
    border: 1px solid gray;
    margin-bottom: 1em;
}
.hcf-form-row textarea {
    margin-bottom: 1em;
}
.home-footer {
    color: black !important;
    margin-left: 2em;
}

.home-afil {
    display:flex;
    flex-direction: row;
    margin-top: 3em;
    flex-wrap: wrap;
    width: 90%;
    padding-left: 10%;
    justify-content: space-between;
}

.home-afil-step {
    color: black;
    flex: 0 0 33%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    text-align: center;
}
.home-afil-step img {
    max-height: 100px;
    max-width: 100%;
    margin-bottom: 2em;
}
.home-afil-step p {
    width: 100%;
}

@media screen and (max-width: 1080px) {
    .image-banner {
        width: 100%;
        padding-bottom: 5em;
        padding-left: 1em;
        position: relative;
    }

    .image-banner h1 {
        padding-top: 5em;
        color: white;
        font-weight: bolder;
        font-size: 2em;
    }
    .image-banner p {
        font-size: 1.2em;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1em;

    }
    .image-banner button {
        font-size: 16px;
        padding: 0.75em
    }
    .image-banner a {
        font-size: 16px;
        color: white;
    }

    .home-cta {
        display:flex;
        flex-direction: column;
    }

    .home-cta a {
        padding-bottom: 0.5em;
    }




    .home-ins-functionality {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .home-ins-functionality p {
        font-weight: bold;
        margin-top: 1em;
        text-align: center;
    }
    .home-ins-functionality img {
        max-width: 200px;
    }




    .home-how-it-works {
        height: 100%;
        padding-left: 1em;
        padding-top: 3em;
        color: black;
        width: 100%;
        display: flex; flex-direction: column; justify-content: space-around;

    }
    .hhiw-img {
        max-width: 200px;
    }
    .hhiw-left {
        flex: 0 0 50%;
    }
    .hhiw-right {
        width: 100%;
        margin-top: 2em;
        padding-left: 2em;
    }
    .home-how-it-works h1 {
        font-size: 1.5em;
        font-weight: bolder;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .home-how-it-works p {
    }

    .hhiw-do {
        border-radius: 2em;
        padding: 1em;
    }

    .hhiw-do-columns {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .hhiw-do-columns ul {
        margin-left: 0;
        padding-left: 0.75em;
        font-size: 0.75em;
    }
    .hhiw-access {
        display:flex;
        flex-direction: column;
        margin-top: 2em;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-content: center;
    }

    .hhiw-access a {
        flex: 0 0 100%;
        margin-bottom: 1em;
    }

    .hhiw-access img {
        max-width: 220px;
    }

    .hhiw-access button {

        font-size: 16px;
        padding: 1em;
    }
    .home-todo-banner {
        background-color: darkred;
        margin-top: 2em;
        color: white !important;
        padding-top: 2em;
        padding-bottom: 1.5em;
    }
    .home-todo-banner h1 {
        color: white;
        margin-bottom: 1em;
    }

    .home-step {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        color: black !important;
        margin-top: 2em;
    }

    .mob-flex-reverse {
        flex-direction: column-reverse;
    }

    .home-step > * {
        flex: 0 0 49%;
        padding-left: 2em;
        padding-right: 2em;
        padding-top: 2em;
    }



    .home-step img {
        height: 100%;
        width: 100%;
        margin:auto;
        text-align: center;
        -webkit-box-shadow: 5px 6px 5px 0px rgba(161,161,161,1);
        -moz-box-shadow: 5px 6px 5px 0px rgba(161,161,161,1);
        box-shadow: 5px 6px 5px 0px rgba(161,161,161,1);
    }

    .home-step h1 {
        font-size: 1.5em;
    }

    .home-step-number {
        width: max-content;
        font-weight: bolder;
    }

    .hhiw-img-lg {
        max-width: 80%;
        margin:auto;
        padding-left: 0;

    }
    .home-contact-form {
        padding-left: 2em;
        color: black !important;
        margin-top: 3em;
        margin-bottom: 5em;
    }
    .home-contact-form h1 {
        font-weight: bolder;
        font-size: 1.75em;
    }
    .home-contact-form p {
        font-size: 0.75em;
    }
    .hcf-form {
        width: 60%;
    }
    .hcf-form button {
        padding: 1em;
        margin-left: 0;
    }
    .hcf-form-row {
        display: flex;
        flex-direction: row;
    }
    .hcf-form-row input {
        border: 1px solid gray;
        margin-bottom: 1em;
    }
    .hcf-form-row textarea {
        margin-bottom: 1em;
    }
    .home-footer {
        color: black !important;
        margin-left: 1em;
    }
    .hhiw-left p {
        font-size: 0.75em;
    }
    .home-ins-title {
        font-weight: bolder;
        text-align: center;
        font-size: 1.2em;
    }
    .home-ins-subtitle {
        text-align: center;
        font-size: 12px;
    }



    .home-afil {
        display:flex;
        flex-direction: column;
        margin-top: 3em;
        flex-wrap: wrap;
    }

    .home-afil-step {
        color: black;
        flex: 0 0 33%;
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        text-align: center;
        width: 90%;
        padding-left: 10%;
    }
    .home-afil-step img {
        max-height: 100px;
        margin-bottom: 2em;
    }
    .home-afil-step p {
        width: 100%;
    }
}
