.transparent-panel {
    background-color: rgba(40, 43, 40, 0.79);
}
.selected-gradient {
    background-color: #C42025;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#C42025), to(#961d22));
    background-image: -webkit-linear-gradient(top, #C42025, #961d22);
    background-image: -moz-linear-gradient(top, #C42025, #961d22);
    background-image: -ms-linear-gradient(top, #C42025, #961d22);
    background-image: -o-linear-gradient(top, #C42025, #961d22);
    background-image: linear-gradient(to bottom, #C42025, #961d22);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#C42025, endColorstr=#961d22);
    color: whitesmoke;
    border-right: 1px solid #1b1c1b;
}
.main-gradient-static {
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
    color: gray !important;
    border-right: 1px solid #1b1c1b;
}
.main-gradient {
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
    color: gray !important;
    border-right: 1px solid #1b1c1b;
}
.main-gradient:hover{
    background-color: #87191c;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#380c0f), to(#961d22));
    background-image: -webkit-linear-gradient(top, #380c0f, #961d22);
    background-image: -moz-linear-gradient(top, #380c0f, #961d22);
    background-image: -ms-linear-gradient(top, #380c0f, #961d22);
    background-image: -o-linear-gradient(top, #380c0f, #961d22);
    background-image: linear-gradient(to bottom, #380c0f, #961d22);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#380c0f, endColorstr=#961d22);
    color:white !important;

}
.red-gradient {
    background-color: #C42025;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#C42025), to(#961d22));
    background-image: -webkit-linear-gradient(top, #C42025, #961d22);
    background-image: -moz-linear-gradient(top, #C42025, #961d22);
    background-image: -ms-linear-gradient(top, #C42025, #961d22);
    background-image: -o-linear-gradient(top, #C42025, #961d22);
    background-image: linear-gradient(to bottom, #C42025, #961d22);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#C42025, endColorstr=#961d22);
    color: whitesmoke;
}
/*
.red-gradient:hover {
    transition: 0.5s;
    background-color: #87191c;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#380c0f), to(#1f060a));
    background-image: -webkit-linear-gradient(top, #380c0f, #1f060a);
    background-image: -moz-linear-gradient(top, #380c0f, #1f060a);
    background-image: -ms-linear-gradient(top, #380c0f, #1f060a);
    background-image: -o-linear-gradient(top, #380c0f, #1f060a);
    background-image: linear-gradient(to bottom, #380c0f, #1f060a);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#380c0f, endColorstr=#1f060a);
    color:gray;
}

 */
.gray-gradient {
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
    color: gray;
    border-right: 1px solid #1b1c1b;
}
.gray-gradient:hover{
    transition: 0.5s;
    background-color: #87191c;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#380c0f), to(#961d22));
    background-image: -webkit-linear-gradient(top, #380c0f, #961d22);
    background-image: -moz-linear-gradient(top, #380c0f, #961d22);
    background-image: -ms-linear-gradient(top, #380c0f, #961d22);
    background-image: -o-linear-gradient(top, #380c0f, #961d22);
    background-image: linear-gradient(to bottom, #380c0f, #961d22);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#380c0f, endColorstr=#961d22);
    color:white !important;
}
.transparent-background {
    background-color: rgba(40, 43, 40, 0.8);

}

@media screen and (max-width:961px) {

    .gray-gradient:hover{
        background-color: #1b1c1b;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
        background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
        background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
        background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
        background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
        background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
        color: gray;
        border-right: 1px solid #1b1c1b;
    }
    .main-gradient:hover{
        background-color: #1b1c1b;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
        background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
        background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
        background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
        background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
        background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
        color: gray !important;
        border-right: 1px solid #1b1c1b;
    }
}
