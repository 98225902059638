
.items-wrapper {
    width: 100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    height: 200px;
    overflow-y: hidden;

}
.item-card {
    width: 48%;
    height: 100%;
    border-radius: 1em;
    font-size: 12px;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.05);
    font-weight: bold;
    background-color: #1b1c1b;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin: 0.1em;
    color:gray;
    flex: 0 0 49%;
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#383c38), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #383c38, #1b1c1b);
    background-image: -moz-linear-gradient(top, #383c38, #1b1c1b);
    background-image: -ms-linear-gradient(top, #383c38, #1b1c1b);
    background-image: -o-linear-gradient(top, #383c38, #1b1c1b);
    background-image: linear-gradient(to bottom, #383c38, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#383c38, endColorstr=#1b1c1b);
}

.item-card-img {
    display: block;
    width:100px;
    height:80px;
    object-fit:scale-down;

    margin :auto;
    transition: 0.5s;
    opacity: 1;
    flex: 1;

}
.item-card-img:hover {
    transition: 0.5s;
    opacity: 0.5;

}
.item-information img {
    max-width: 200px;
    border-radius: 100px;
}
.item-card-content {
    margin: auto;
    text-align: left;
    width: 95%;
    height: 100%;
}

.image-picker {
    overflow-x: scroll;
    border: 2px solid gray;
    display: flex;
    flex-direction: row;
}
.image-picker-img-wrapper {
    margin: 0.1em;
    cursor: pointer;
}
.image-picker-img {
    height: 70px;
    width: 70px;
}
.item-card-title {
    font-size: 14px !important;
    margin-top: 0.5em;
    margin-bottom: 0;
    color: white;
    border-bottom: none;
}
.item-card-description {
    font-size: 12px !important;
    font-weight: lighter;
    margin-bottom: 0;
}


.Collapsible {

    margin-top: 0.5em;
}
.Collapsible__contentOuter {
    background-color: rgba(40, 43, 40, 0.8);
}
.Collapsible__contentInner {
    padding: 10px;
    border-top: 0;
}

.Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
}

.Collapsible__contentInner p:last-child {
    margin-bottom: 0;
}


.Collapsible__trigger {
    text-align: left;
    width: 100%;
    font-weight: bolder;
    font-size: 1em;
    cursor:pointer;
    display: block;
    text-decoration: none;
    position: relative;
    padding: 10px;
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
    color: white;
    border-right: 1px solid #1b1c1b;
    font-weight: bolder !important;

}
.Collapsible__trigger:after {
    font-family: "Font Awesome 5 Free";
    content: "\f078";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
    color: white
}
.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }
.CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
    background-color: darkslateblue;
}

.Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black;
}


@media screen and (max-width:961px) {
    .Collapsible {

        margin-top: 0em;
    }
    .Collapsible__contentInner {
        padding: 0px;
    }
    .Collapsible__contentInner p {
        margin-bottom: 10px;
        font-size: 8px;
        line-height: 20px;
    }
    .Collapsible__trigger:after {
        font-size: 7px;
    }
    .Collapsible__trigger {
        font-size: 10px;
        padding: 0.7em;
        border-top: 1px solid black;
    }
    .item-card-img {
        width:34px;
        height:34px;
        padding: 0;
        border: 1px solid gray;
        border-radius: 2em;
    }
    .item-card {
        height: 70px;
        flex: 0 0 33.33333%;
        background-color: #1b1c1b;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#383c38), to(#1b1c1b));
        background-image: -webkit-linear-gradient(top, #383c38, #1b1c1b);
        background-image: -moz-linear-gradient(top, #383c38, #1b1c1b);
        background-image: -ms-linear-gradient(top, #383c38, #1b1c1b);
        background-image: -o-linear-gradient(top, #383c38, #1b1c1b);
        background-image: linear-gradient(to bottom, #383c38, #1b1c1b);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#383c38, endColorstr=#1b1c1b);
    }
    .items-wrapper {
        height: 80px;
    }
    .item-card-title {
        font-size: 7px !important;
        margin-top: 0.5em;
        margin-bottom: 0;
        color: white;
        border-bottom: none;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

    }
    .item-card-description {
        font-size: 0px !important;
        font-weight: lighter;
        margin-bottom: 0;
    }

    .image-picker-img {
        height: 25px;
        width: 25px;
    }
    .preview-img {
        height: 24px;
        width: 24px;
        margin: auto;
        margin-top: 0.1em;
        margin-bottom: 0.5em;
        border: 1px outset black;
    }
}

