
.dashboard-side-margin {
    margin-left: 250px;
}
.dashboard-apps {
    display: flex;
    flex-direction: row;
}
/* Card */

.custom-card-main {
    width: 50%;
    height: 100%;
    margin: 5em;
    background-color: white;
    border-radius: 0.5em;
    font-family: "Helvetica", "Arial", sans-serif;
    font-weight: lighter;
    line-height: 1.5em;
    font-size: 0.9em;
    color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


}
.custom-card-header {
    position:relative;
    top:-30px;
    background-color: #C73B3D;
    width: 95%;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: white;


}
.custom-card-header p:first-child {
    font-size: 1.4em;
}

.custom-card-footer {
    border-top: 1px solid gray;

}

.custom-card-button {
    background-color: #C73B3D;
    color: white;
    margin: 1em;
    border: none;
    border-radius: 0.3em;
    padding: 0.8em 2em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

