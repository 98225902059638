

.side-bar {
    background-color: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 250px;
    height: 85%;
    color:white;
    z-index: 999;
    font-family: Roboto, sans-serif;
    -webkit-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.75);
}
.side-bar-top {
    display:flex;
    flex-direction: column;
    border-bottom: 1px solid gray;
}
.side-bar-top-left {
    text-align: center;
}
.side-bar-top-left img {
    margin-top: 0.5em;
    max-width: 120px;
    margin-bottom: 0.5em;
}
.side-bar-top-right {
    width: 100%;
    text-align: center;

}
.selected-link {
    background-color: #E6E6E6;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E6E6E6), to(#CCCCCC));
    background-image: -webkit-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -moz-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -ms-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -o-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: linear-gradient(to bottom, #E6E6E6, #CCCCCC);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#E6E6E6,
    endColorstr=#CCCCCC);
}
.side-bar-title {
    text-align: center;
    font-weight: bolder;
    color:gray;
}
.sub-title {
    text-align: center;
    font-weight: lighter;
}
.side-bar-links {
    height: 100%;
    width: 100%;
    background-color: white;
}
.side-bar-links a:hover {
    color:white;
    text-underline: none;
}
.side-bar-link {
    display:flex;
    flex-direction: row;
    color: gray !important;
    text-overflow: ellipsis;
    transition: 0.5s;

}

.side-bar-link p {
    width: 100%;
    text-align: right;
    margin-right: 0.3em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.side-bar-link:hover {
    transition: 0.5s;
    background-color: #E6E6E6;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E6E6E6), to(#CCCCCC));
    background-image: -webkit-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -moz-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -ms-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -o-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: linear-gradient(to bottom, #E6E6E6, #CCCCCC);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#E6E6E6,
    endColorstr=#CCCCCC);
}

.side-bar-link:first-child {
}
