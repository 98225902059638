@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', sans-serif !important; /*Use all as Open Sans for now.*/
}
::-webkit-scrollbar {
    width: 5px;
    height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #282b28;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #961d22;
    border: 1px solid #22231f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #961d22;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:hover {
    text-decoration: none;
}

#title {
    margin: auto;
    padding: 0.5em;
    text-align: center;
    color: white;
}

#app-container {

}

.nav-element {
    height: 100%;
    display: flex;
    width: 100%;

}

.navigation-list {

}

.navigation-list > * {
    width: 100%;
    padding: 10px;
    margin: 0;
    font-size: 3em;
}

.logo2 {

    border-bottom: 3px solid purple;
}

.nav-list {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: 0;
}

.company-logo {

}

.company-logo img {
}

.reupload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    border: 2px solid red;
    position:relative;
    bottom: 0;
}

.top-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 1em;
    padding: 1em;
}

.items-list {
    border: 2px solid grey;
    display: flex;
    flex-direction: row;
    height: 200px
}

.example-item {
    border: 1px solid black;
    width: 100px;
    margin: 10px;
}

.controls {

}

.logo-container {
    display: flex;
    align-content: center;
    text-align: center;
    justify-content: center;
}

.register-form {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
}

.login-container-wrapper,
.item-folder-wrapper,
.confirmation-box-wrapper,
.create-account-wrapper {
    max-width: 528px;
    padding-right: 0;
    min-width: 450px;
    margin: 0 auto;
    height: 90%;

}
.create-account-wrapper {
    height: 70%;
}
.row-flex {
    display: flex;
    flex-direction: row;
}

.create-item-wrapper {
    width: 100%;
    height: 85%;
    margin: auto;
}

.page-wrapper {
    margin: 0.4em;
}

.login-container,
.item-folder-inner,
.create-item-inner,
.confirmation-box-inner,
.create-account-container {

    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.75);
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: gray;
}
.create-account-container {
    height: 70%;
}
.item-folder-inner {
    height: 80%;
    margin-top: 1em;
    width: 75% !important;
    margin-left: 8%;
}

.category-inner {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #cdcdcd;
    box-shadow: 0 10px 25px #111111;
    position: absolute;
    top: 25%;
    left: 40%;
    width: 25%;
}

.category-inner h3 {
    font-size: 1.4em;
}

.color-being-overriden {
    color: black;
}

.color-being-overriden:hover {

}

#admin-sidebar {
    background-color: lightgrey;
}

.item-folder-inner {
    padding: 0;
    width: 60%;
}

.straight-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.straight-row2 {
    display: flex;
    border-top: 1px solid lightgrey;
    margin-top: 0.2em;
}

.icon-picker {
    top: 50%;
    margin-top: 30%;
}

.create-item-inner {
    margin: auto;
    margin-left: 8%;
    margin-top: 1%;
    height: 100%;
    width: 80%;
    background-color: whitesmoke;
}

.linkable-text {
    cursor: pointer;
    padding: 20px;
    font-family: Roboto, sans-serif;
    color: #1B66CA !important;
}

.register-bg {
    height: 100%;
}

.red-text {
    color: red;
}

.auth-error {
    margin: 50px;
}


.create-category {
    display: flex;
    flex-direction: column;
    padding: 1em;
}



.shadow-screen {
    position: fixed;
    padding: 0;
    margin: 0;

    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.73);
    z-index: 100;
}


.clickable-text {
    padding-left: 10px;
    cursor: pointer;
}

.clickable-text:hover {
    text-decoration: underline;
    color: steelblue;
}

.icon-sm {
    padding-left: 10px;
}

.mini-renderer {
    width: 95%;
    height: 95%;
}

.popup-footer {
    border-top: 1px solid gray;
    display: flex;
    justify-content: flex-end;
    height: 10%;

}

.bottom-buttons {
    margin: 2em;
}


.at-the-end {
    display: flex;
    justify-content: flex-end;
}

.default-button {
    background: linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
    background-color: #f9f9f9;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    display: inline-block;
    cursor: pointer;
    color: #666666;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #ffffff;
    margin: 0.5em;
    border: 1px solid black;
    outline:none;
}

button {
    outline: none;
}
button::-moz-focus-inner {
    border: 0;
}
button:focus {
    border: 0;
    outline: none;
}

.default-button:hover {
    background: linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
    background-color: #e9e9e9;
}

.default-button:active {
    position: relative;
    top: 1px;
}

.photo-frame {
    max-height: 100px;
    max-width: 100px;
    border-radius: 20%;
    overflow: hidden;
    padding: 0;
    box-shadow: 0 10px 25px #818181;
    border: 1px solid gray;
    margin: auto;
    margin: 1em auto;
}

.obj-uploader {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid gray;
}

.obj-uploader-details {
    margin: auto;
}

.make-static > {
    position: static;
}

.catalogue-advertiser-panel {
    border-bottom: 2px inset lightgray;
    cursor: pointer;
    transition: 0.5s;
    height: 3.5em;
    padding: 0.5em;
}
.catalogue-advertiser-panel:hover {
    background-color: whitesmoke;

}

.catalogue-advertiser-avatar {
    height: 90%;
    transition: 0.5s;

}
.catalogue-advertiser-avatar:hover {

}
#side-panel-title h2 {
    font-size: 2em !important;
    font-family: Roboto, sans-serif;
    margin-top: 0.2em;
}
.tab-open {
    font-size: 0.9em;
    text-align: center;
    font-weight: 200;
    margin-left: 10px;
    margin-top: 10px;
}
/* Imported in public/index.html */
@import url('https://fonts.googleapis.com/css?family=Noto+Serif|Righteous|Source+Serif+Pro&display=swap');

/*#region Home*/

body {
    background-color: lightgray;
    color: black;
    font-family: 'Open Sans', serif !important;
    overscroll-behavior-x: none;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
}

h1 {
    color: black;
}

h2 {
    font-size: 1.4em;
    text-decoration: none;
}


#logo {
    max-width: 300px;
    display: flex;
    margin: auto;
    text-align: center;
}

#top-container {
    width: 100%;
    background-color: lightgray;
    z-index: 1;
    border-bottom: 4px solid white;
    padding: 20px;
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
}

/* NAV BAR */
.navbar {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 0.3em;
}

.navbar ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0px;
}

.title {
    font-weight: 300;
    font-family: 'Source Serif Pro', serif;
    display: flex;
    text-align: center;
    padding: 0.2em;
    justify-content: flex-start;
    border-bottom: 1px solid gainsboro;

}

.side-panel-inner {
    position: relative;
    height: 100%;
    font-weight: lighter;
}

.go-back-btn {
    height: 2em;
}
.game-container {
    width: 100%;
}
.advertiser-title-panel {
    display:flex;
    flex-direction: row;
    text-align: center;
    margin-left: 0.8em;
}
.go-back-img {
    width: 10%;
    height: 10%;
    margin-top: 1em;
    margin-right: 1em;
    cursor: pointer;
}
.advertiser-logo {
    width: 65%;

}
.advertiser-item-list {

}

.selectBox {
    border: 1px solid #86000f;
    background-color: rgba(108, 168, 255, 0.53);
    position: fixed;
}


.app-container {
    text-align: center;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
}


#login-info {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 1em;
}

.navbar li {
    padding-right: 2em;
    font-weight: bolder;
}

.navbar li:hover {
    color: blue;
}

.navbar li:first-letter {
    text-transform: uppercase;
}

.active {
    color: #C42025;
}


#tile-controls {
    position: fixed;
    bottom: 50px;
    left: 700px;
    z-index: 3;
}

#object-tools {
    border: 2px solid black;
    position: absolute;
    z-index: 2;
    right: 200px;
    bottom: 50px;
}

#object-buttons img {
    max-width: 2em;
}

#object-buttons a {
    padding: 0.5em
}

#rotation-tools {
    text-align: center;
    margin: auto;
}

#circle {
    width: 25px;
    height: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border: 2px solid black;
    background: white;
}


/*
SECTION SIDE TABS
*/


.dev-modes {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 5;
    text-align: center;
    height: 4em;
    padding: 1em;
    border: 1px solid red;

}
.dev-modes_mobile2 {
    display: flex;
    flex-direction: row;
    position: fixed;
    left: 25px;
    top: 80px;
    z-index: 5;
    text-align: center;
}

.item-controls {
    position: absolute;
    left: 10px;
    top: 250px;
    padding: 1em;
}
.obj-controls {
    display:flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}
.obj-controls button {
    flex: 0 0 33.3% !important;
    justify-content:center;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.obj-controls button img {
    max-width: 24px;
}
.obj-controls button p {
    font-size: 12px;
}
.rotate-controls {
    display:flex;
    flex-direction: row;
}

.rotate-controls button {
    flex: 0 0 50%;
}
.rotate-controls button img {
    max-width: 24px;
}
.rotate-controls button p {
    font-size: 12px;

}

#menu-button-toggle {
    position: absolute;
    margin: auto;
    bottom: 5em;
    left: 900px;
    font-size: 1.5em;
}
#floorplanner-canvas {
    background-color: #959595
}

#home-tab:after {
    content: "\f015";
}

#catalogue-tab:after {
    content: "\f25a";
}

#cart-tab:after {
    content: "\f07a";
}

#settings-tab:after {
    content: "\f085";
}

#information-tab:after {
    content: "\f129";
}

#customize-tab:after {
    content: "\f1fc";
}

/*
 REGION Customize-Room
*/
.customize-section {
    border: 2px solid white;
}


.resize-room-inputs {
    text-align: left;
    padding: 10px;
}

#resize-room-section input {
    width: 100px;
}

/*
 REGION Cart
 */

#top-cart-section {
    font-size: 17px;
    font-weight: 900;
    width: 80%;
    height: 100%;
    text-align: left;
    margin-left: 1em;
    margin-top: 1em;
}

#bottom-cart-section {
    margin: 2em;
    padding-bottom: 1em;
}

#extra-buttons {
    position: absolute;
    right: 10px;
    padding: 0px;
    bottom: 40px;
    display: flex;
    flex-direction: column;
}


.selected {
    color: red;
}

#floor-planner {
    background-color: #1b1c1b;
}

.ui-toolip {
    width: auto;
    height: auto;
}

.interface-controls {
    margin: 0 auto;
    text-align: center;
    position: fixed;
    bottom: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1em;
    width: 20em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.interface-controls_mobile {
    margin: 0 auto;
    text-align: center;
    position: fixed;
    bottom: 10px;
    left: 10px;
    border: 1px solid lightgrey;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 1em;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    padding: 0.2em;
    width:20%;

}
.interface-controls2 {
    margin: 0 auto;
    text-align: center;
}

#wall-measurements {
    background-color: white;
    padding: 1em;
    position: absolute;
    bottom: 10px;
    margin: auto;
    left: 380px;
    width: 15em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#wall-measurements_mobile {
    background-color: white;
    padding: 1em;
    position: absolute;
    bottom: 0px;
    margin: auto;
    left: 20px;
    border: 1px solid black;
    width: 15em;
    display:flex;
    flex-direction: row;
}

#pan-controls {
    position: fixed;
    bottom: 5px;
    left: 10px;
}
#pan-controls img {
    max-width: 12px;
}
#pan-controls_mobile {
    position: fixed;
    bottom: 5px;
    left: 10px;
}
#pan-controls_mobile img {
    max-width: 12px;

}
#camera-controls {
    float: left;
    /* 	right: 50%; */
    /* 	bottom: 50px; */
}

#floorplanner-body, #viewer {
    position: absolute;
    width: 100vw;
    height: 100vh;
}


#main-controls {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 0;
}

#walltextures {
    position: absolute;
    right: 100px;
    top: 100px;
}

.btn-file {
    display: inline-block;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    filter: alpha(opacity=0);
    opacity: 0;
    cursor: inherit;
    display: block;
}

.btn-hint {
    position: absolute;
    left: 20px;
    bottom: 20px;
    background-color: rgba(0, 0, 0, 0.50);
    color: #ffffff;
    padding: 5px 10px;
    z-index: 10;
    display: none;
}


.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #428bca;
}

.sidebar {
    background-color: whitesmoke;
}

.navi-item-active {
    width: 8em;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #ffffff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
    font-weight: bold;
    color: #FFFFFF;
    background-color: #ff9a9a;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff9a9a), to(#ff4040));
    background-image: -webkit-linear-gradient(top, #ff9a9a, #ff4040);
    background-image: -moz-linear-gradient(top, #ff9a9a, #ff4040);
    background-image: -ms-linear-gradient(top, #ff9a9a, #ff4040);
    background-image: -o-linear-gradient(top, #ff9a9a, #ff4040);
    background-image: linear-gradient(to bottom, #ff9a9a, #ff4040);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#ff9a9a, endColorstr=#ff4040);
}

.bg-normal {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-decoration: none;
    color: black;
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
}

.bg-normal-interactive {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-decoration: none;
    color: black;
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
}
.bg-normal-interactive:hover {
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
}

.navi-item {
    width: 7em;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #ffffff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
    font-weight: bold;
    color: black;
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
}

.nav-bar-active {
    border: 1px solid #ffffff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
    font-weight: bold;
    color: #FFFFFF;
    background-color: #ff9a9a;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff9a9a), to(#ff4040));
    background-image: -webkit-linear-gradient(top, #ff9a9a, #ff4040);
    background-image: -moz-linear-gradient(top, #ff9a9a, #ff4040);
    background-image: -ms-linear-gradient(top, #ff9a9a, #ff4040);
    background-image: -o-linear-gradient(top, #ff9a9a, #ff4040);
    background-image: linear-gradient(to bottom, #ff9a9a, #ff4040);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#ff9a9a, endColorstr=#ff4040);
}

.inventory-content {
    max-height: 500px;
    overflow-y: scroll;
}

/* .ui-dialog, .ui-dialog-content, .ui-widget-content */
/* { */
/* 	min-width: 50%; */
/* 	max-height: 500px; */
/* 	height: 500px; */
/*     overflow: auto; */
/*     position: relative; */
/*     padding: 0 !important; */
/* } */

/* Enable multiple lines in bootstrap tooltips */
.tooltip-inner {
    white-space: pre-wrap;
}

.dg select {
    color: #000000;
}


#floorplanner-view-controls {
    font-size: 12px;
}

#canvas-2d-controls {
    position: absolute;
    z-index: 100;
    display: none;
}

.btn-stretch {
    width: 100%;
}

.save-load {
    display: flex;
    flex-direction: row;
    position:absolute;
    bottom: 55px;
    width: 100%;
}
.btn-low {
    font-size: 0.7em !important;
    font-family: Roboto, sans-serif;
    padding: 0.2em;
    height: 40px;
    border: none;
    border-right: 1px solid #1b1c1b;
    width: 100px;
}
.btn-low img {
    width: 16px;
}

.btn-io {
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
    color: gray;
}
.btn-low:hover {
    background-color: #c42227;
}
.button_custom {
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
    font-weight: bold;
    color: gray;
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
    border: none;
}
.button_custom:hover {
    color: white !important;
}
.button_custom img, .button_custom_selected img {
    margin-right: 0.4em;
    max-width: 20px;
}
.button_custom p, .button_custom_selected p {
    font-size: 1em;
}

.button_custom_selected {
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
    font-weight: bold;
    color: white;
    background-color: #c42025;
}

.cart-section {
    background-color: #E6E6E6;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E6E6E6), to(#CCCCCC));
    background-image: -webkit-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -moz-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -ms-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -o-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: linear-gradient(to bottom, #E6E6E6, #CCCCCC);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#E6E6E6, endColorstr=#CCCCCC);
}

.gradient-gray-white {
    background-color: #E6E6E6;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E6E6E6), to(#CCCCCC));
    background-image: -webkit-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -moz-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -ms-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: -o-linear-gradient(top, #E6E6E6, #CCCCCC);
    background-image: linear-gradient(to bottom, #E6E6E6, #CCCCCC);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#E6E6E6, endColorstr=#CCCCCC);
}

.register {
    padding: 3%;
    height: 100%;
    position: absolute;
    width: 100%;
}

.register-left {
    text-align: center;
    color: #fff;
    margin-top: 4%;
}

.register-left input {
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;
    background: #f8f9fa;
    font-weight: bold;
    color: #383d41;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
}

.register-right {
    background: #f8f9fa;
    border-top-left-radius: 10% 50%;
    border-bottom-left-radius: 10% 50%;
}

.register-left img {
    margin-top: 15%;
    margin-bottom: 5%;
    width: 25%;
    -webkit-animation: mover 2s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

.register-left p {
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
}

.register .register-form {
    padding: 10%;
    margin-top: 10%;
}

.btnRegister {
    float: right;
    margin-top: 10%;
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    background: #0062cc;
    color: #fff;
    font-weight: 600;
    width: 50%;
    cursor: pointer;
}

.register .nav-tabs {
    margin-top: 3%;
    border: none;
    background: #000000;
    border-radius: 1.5rem;
    width: 28%;
    float: right;
}

.register .nav-tabs .nav-link {
    padding: 2%;
    height: 34px;
    font-weight: 600;
    color: #fff;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.register .nav-tabs .nav-link:hover {
    border: none;
}

.register .nav-tabs .nav-link.active {
    width: 100px;
    color: #0062cc;
    border: 2px solid #0062cc;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}

.register-heading {
    text-align: center;
    margin-top: 8%;
    margin-bottom: -15%;
    color: #495057;
}

.login-panel {
    border: 2px solid blue;
}


.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    text-align: center;
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
}

.avatar-img {
    max-width: 10em;
    max-height: 10em;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link + .card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-weight: bold;
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card-group > .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .card-group > .card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    padding: 1em;
}

#loading-screen {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    opacity: 1;
    transition: 0.2s opacity;
}

#loading-screen.fade-out {
    opacity: 0;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #85171a;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #C42025;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ea262c;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.edit-item-panel {
    position: absolute;
    left: 0;
    padding: 1em;
    z-index: 5;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 25px #cdcdcd;
}

.drag-handle {
    border: 2px solid green;
    width: 32px;
    height: 32px;

}

.delete-bar {
    background-color: #2872DC;
    border-radius: 5em;
    color: white;
    display: flex;
    flex-direction: row;
    padding: 1em;
    height: 4em;
    border: 1px solid #c4c0c0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    text-decoration: none;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.05);
    font-weight: bold;
}

.delete-bar button {
    align-self: flex-end;
}

.info-bar {
    background-color: #f6f6f6;
    border-bottom: 1px solid lightgray;

}


.route-title {
    font-family: "Google Sans", sans-serif;
    color: #213b56;
    margin: 0.4em;
}

.route-title-mini {
    font-family: "Google Sans", sans-serif;
    color: #213b56;
}

input {
    background-color: #f6f6f6;
    border: 2px solid red;
}

.flex-start {
    align-self: flex-start;
    margin: 1em;
}

.flex-end {
    margin: 2em;
}

.object-uploader-wrapper {
    margin: auto;
    text-align: center;
    height: 100%;
    display: table;

}

.object-uploader-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    display: table-cell;
    vertical-align: middle;
}

.popup-title {
    border-bottom: 1px solid gray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 10%;
}

.modal-header h3 {
    font-size: 1.5em;
    font-weight: bolder;
}

.column-stretch-width {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.second-step-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 80%;
    overflow: hidden;
    min-height: 0;
}

.second-step-left {
    overflow: hidden;
    padding-top: 1em;
    width: 70%;
}

.second-step-right {
    width: 30%;
    padding: 1.5em;
    height: 100%;
    flex: 1;
    overflow: auto;
}

.second-step-right-inner {
    overflow-y: hidden;
    position: relative;
    height: 900px;
    min-height: 0;
    padding: 1em;
}

.large-input {
    border: 1px solid lightgray;
    border-radius: 0.5em;
    width: 90%;
}

.large-input-header {

}

.home-content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100vh;
    color: black;
    font-weight: lighter;
    font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    text-align: center;
    pointer-events:none;
}

.home-content p {
    font-size: 1.4em;
    font-weight: 200;
}
.home-content em {
    color: #1B66CA;
    font-weight: 900;
}

.aws-btn {

    --button-default-height: 48px;
    --button-default-font-size: 14px;
    --button-default-border-radius: 6px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 5px;
    --button-hover-pressure: 2;
    --transform-speed: 0.185s;
    --button-primary-color: #a9d3e9;
    --button-primary-color-dark: #58a9d4;
    --button-primary-color-light: #2e84b2;
    --button-primary-color-hover: #95c9e4;
    --button-primary-border: none;
    --button-secondary-color: #fafafa;
    --button-secondary-color-dark: #67cbc3;
    --button-secondary-color-light: #349890;
    --button-secondary-color-hover: #ecf9f8;
    --button-secondary-border: 2px solid #b3e5e1;
    --button-anchor-color: #95d44a;
    --button-anchor-color-dark: #52a934;
    --button-anchor-color-light: #3f8228;
    --button-anchor-color-hover: #89cf35;
    --button-anchor-border: 2px solid #5bbd3a;
}

.large-input input {
    border: none;
    background-color: white;
    height: 4em;
    padding: 0.2em;
}

/*
https://animista.net/
 */
.slide-in-elliptic-top-fwd {
    -webkit-animation: slide-in-elliptic-top-fwd .7s cubic-bezier(.25, .46, .45, .94) both;
    animation: slide-in-elliptic-top-fwd .7s cubic-bezier(.25, .46, .45, .94) both
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1
    }
}

@keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1
    }
}


.slide-out-blurred-top-immediate {
    -webkit-animation: slide-out-blurred-top .0s cubic-bezier(.755, .05, .855, .06) both;
    animation: slide-out-blurred-top .0s cubic-bezier(.755, .05, .855, .06) both
}

.slide-in-blurred-top {
    -webkit-animation: slide-in-blurred-top .6s cubic-bezier(.23, 1.000, .32, 1.000) both;
    animation: slide-in-blurred-top .6s cubic-bezier(.23, 1.000, .32, 1.000) both
}

.slide-out-blurred-top {
    -webkit-animation: slide-out-blurred-top .45s cubic-bezier(.755, .05, .855, .06) both;
    animation: slide-out-blurred-top .45s cubic-bezier(.755, .05, .855, .06) both
}

@-webkit-keyframes slide-out-blurred-top {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
    100% {
        -webkit-transform: translateY(-1000px) scaleY(2) scaleX(.2);
        transform: translateY(-1000px) scaleY(2) scaleX(.2);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0
    }
}

@keyframes slide-out-blurred-top {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
    100% {
        -webkit-transform: translateY(-1000px) scaleY(2) scaleX(.2);
        transform: translateY(-1000px) scaleY(2) scaleX(.2);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0
    }
}

@-webkit-keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
}

@keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
}

.hidden {
    display: none;
}
#hidden {
    display:none;
}
.fade-in-half {
    -webkit-animation: fade-in-half 0.5s linear forwards;
    animation: fade-in-half 0.5s linear forwards;

}

.fade-out-half {
    -webkit-animation: fade-out-half 0.5s linear forwards;
    animation: fade-out-half 0.5s linear forwards;
}

@-webkit-keyframes fade-in-half {
    0% {
        opacity: 0;

    }
    100% {
        opacity:0.75;
        z-index: 0;
    }
}

@keyframes fade-in-half {
    0% {
        opacity: 0;

    }
    100% {
        opacity: 0.75;
        z-index: 0;

    }
}

@-webkit-keyframes fade-out-half {
    0% {
        opacity: 0.75;
        display:none;
        z-index: -999;

    }

    100% {
        opacity: 0;
        display:none !important;
        z-index: -999;

    }
}

@keyframes fade-out-half {
    0% {
        opacity: 0.75;
        display:none;
    }

    100% {
        opacity: 0;
        display:none !important;
        z-index: -999;

    }
}



.fade-in {
    -webkit-animation: fade-in 0.5s linear forwards;
    animation: fade-in 0.5s linear forwards;

}

.fade-out {
    -webkit-animation: fade-out 0.5s linear forwards;
    animation: fade-out 0.5s linear forwards;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;

    }
    100% {
        opacity:1;
        z-index: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;

    }
    100% {
        opacity: 1;
        z-index: 0;

    }
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
        display:none;
        z-index: -999;

    }

    100% {
        opacity: 0;
        display:none !important;
        z-index: -999;

    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
        display:none;
    }

    100% {
        opacity: 0;
        display:none !important;
        z-index: -999;

    }
}

.shadow-pop-tr {
    -webkit-animation: shadow-pop-tr .3s cubic-bezier(.47, 0.000, .745, .715) both;
    animation: shadow-pop-tr .3s cubic-bezier(.47, 0.000, .745, .715) both
}

@-webkit-keyframes shadow-pop-tr {
    0% {
        -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
        box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
    100% {
        -webkit-box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
        box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
        -webkit-transform: translateX(-8px) translateY(8px);
        transform: translateX(-8px) translateY(8px)
    }
}

@keyframes shadow-pop-tr {
    0% {
        -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
        box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
    100% {
        -webkit-box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
        box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
        -webkit-transform: translateX(-8px) translateY(8px);
        transform: translateX(-8px) translateY(8px)
    }
}


.bottom-border {
    border-bottom: 1px solid lightgrey;
    padding-top: 0.5em;
    padding-left: 0.5em;
}

.bottom-border h3 {
    margin: 0.2em
}

.center-element {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.icon-picker {
    border: 2px solid red;
    width: 50%;
}

.database {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 90%;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    color: black;
    background-color: #282b28;
}

.database-column {
    border-right: 2px solid #717171;
    width: 100%;
    overflow-y: auto;

}
.bottom-border-light {
    border-bottom: 2px solid #717171;
    padding: 1em;
    height: 10%;
}

.database-scroll {


}

.column-one {
    width: 33%;
}

.column-two {
    width: 33%;
    color: black;

}

column-three {
    width: 66%;
    color: black;

}

.database-column-title {
    border-bottom: 2px solid #717171;
    color: gray !important;
    text-align: center;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-around;

}


.database-item:hover {
    background-color: lightgrey;
    color: black;
    transition: 0.5s;
    cursor:pointer;
}


.database-item img,
.database-column-title img {
    max-width: 32px;
    max-height: 32px;
    margin-left: 0.5em;
}

.database-create,
.database-item,
.database-column-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    color: #989898;
    transition: 0.5s;

}

.database-create {
    color: #0649ff;
    cursor: pointer;
}

.database-create p,
.database-item p,
.database-column-title p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 5px;
    text-align: center;
}

.center-span {
    margin: 10px;
    top: 20%;
    left: 10%;
}

.center-span-right {
    margin: 0;
    position: absolute;
    top: 20%;
    right: 10%;
}

.database-create:hover {
    background-color: lightblue;
}

.folder-controls-header {
    display: flex;
    flex-direction: row;
}

.folder-controls-header * {
    margin-right: 1em;
    color: gray;
}

.folder-controls-header span {
    color: gray;
    cursor: pointer;
    font-size: 24px;
}

.folder-controls-header span:hover {
    color: black;
}

.edit-item-details,
.database-scroll {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.edit-item-details {
    height: 100%;
}

.keep-height-100 {
    padding: 10px;
    height: 40em;
    background-color: white !important;
}

.controls {
    position: fixed;
    left: 300px;
    bottom: 150px;
    z-index: 9999;
    border: 2px solid red;
}

.profile-avatar {
    border-radius: 2em;
}


.rotate-img {
    width: 1em;
}
.customize-room {
    height: 100%;
 }


.customize-room section {
    margin: auto;
    display:flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.cart-item {
    margin:0;
    border: 1px solid lightgray;
}

.file-dropzone {
    background: repeating-linear-gradient(
            45deg,
            #b4b4b4,
            #b4b4b4 10px,
            #e8e8e8 10px,
            #e8e8e8 20px
    );
    height: 10vh;
}
.diagnose-panel {
    position:absolute;
    left:0;
    bottom:0;
    z-index: 999999999;
    background-color: black;
    color: whitesmoke;
}
.dragndropimg {
    width: 30px;
    cursor:pointer;
    border: none;
    border-radius: 1px;
    margin-left: 0.5em;
}


.preview-img {
    height: 82px;
    width: 82px;
    margin: auto;
    margin-top: 0.1em;
    margin-bottom: 0.5em;
    border: 1px outset black;
}
.dragndropzone-outer {
    background-color: white;
    color: black;
    padding: 5px;
    border-radius: 4px;
    width:100%;
    height: 70%;
    cursor: pointer;
    margin-top: -100px;
    border: 1px solid black;
}
.dragndropzone {
    border: 2px outset black;
    border-radius: 4px;

    width: 100%;
    height: 60%;
    background: repeating-linear-gradient(
            45deg,
            #b4b4b4,
            #b4b4b4 10px,
            #e8e8e8 10px,
            #e8e8e8 20px
    );
}
.loading-screen-create {
    text-align: center;
    height: 85%;

}
.loading-screen-create p {
    margin: auto;
    text-align: center;
    font-weight: bolder;
    font-size: 2em;
}
.obj-uploader-details i {
    font-size: 12px;
}

#model-viewer {
    width: 75%;
    height: 75%;
    background: #FFC107;

    z-index: 999999;
    color: white;
    text-align: center;
    pointer-events: none;
    font-family:Monospace;
    font-size:13px;
    margin: auto;
    margin-top: 2em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#model-viewer-container {
    z-index: 9999999;
}
.noselect {
     -webkit-touch-callout: none; /* iOS Safari */
     -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
     -ms-user-select: none; /* Internet Explorer/Edge */
     user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
 }
#container {
    position: absolute;
    width: 100%;
    border: 2px solid purple;
    height: 100%;
}

.span-delete {
    cursor: pointer;
}
.span-delete:hover {
    color: red;
}

.box {
    background-color: grey;
}

.centre-big-button {
    border: 2px solid gray;
    padding: 2em;
    margin: auto;
}

.subtitle-panel {
    border-bottom: 1px solid gray;
    padding: 0;
}
.subtitle-panel p {
    font-size: 14px;
    text-align: left;
}


.title-clean,
.title-clean-lg{
    text-align: left;
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 14px;
    font-weight: bolder;
    color: white;
    margin-top: 0.1em;
    margin-bottom: 0.5em;
    border-right: 1em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.title-clean-lg {
    font-size: 18px;
    padding: 1em;
    padding-bottom: 0.4em;
}
.subtitle-clean {
    text-align: left;
    color: gray;
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 12px;
    margin-top: 0.75em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.transparent-button,
.transparent-button-main{
    padding: 1em 4em;
    background-color: transparent;
    border-radius: 1em;
    border: 2px solid white;
    color: white;
    transition: 0.5s;
}
.transparent-button-main {
    background-color: #1B66CA;
    border: none;
    margin: 0 20px;
}
.transparent-button:hover {
    background-color: rgba(255, 255, 255, 0.25);
}
.transparent-button-main:hover {
    background-color: #C42227;

}

.msuite-btn-general,
.msuite-btn {
    padding: 5px 20px;
    font-family: Roboto, sans-serif;
    background-color: #1B66CA;
    color: white;
    font-weight: 200;
    font-size: 16px;
    border: none;
    border-radius: 0.2em;
    transition: 0.5s;
    margin: 0.2em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.msuite-btn {

    position: absolute;
    right: 30px;
    bottom: 30px;

}

.msuite-btn:hover,
.msuite-btn-general:hover {
    background-color: #C42227;
    transition: 0.5s;

}

.home-information {
    background-color: white;
    height: 1000px;
    bottom: -1000px;
    width: 100%;
    position: absolute;
}

.msuite-mob-img {
    position: absolute;
    right: 0;
    bottom: 0;
    display:flex;
    flex-direction: column;
}

.gplay-badge {
    max-width: 10em;
    position: absolute;
    bottom: 100px;
    left: 235px;
}

.home-overlay {

}

.home-section {
    display:flex;
    margin: 5em;
}

.home-section img:last-of-type {
    max-width: 40em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.title-and-sub {
    width: 50%;
    text-align: left;
    margin-left: 2em;
}

.title-and-sub p:first-child {
    font-size: 2em;
    font-weight: bold;
}
.title-and-sub p:last-child {

}

.home-screen-right {
    position: absolute;
    left: 0;
    bottom: 5em;
    display:flex;
    flex-direction: column;
}

.presets {

}

.preset {
    cursor:pointer;
    margin: 1em;
    display:flex;
    flex-direction: column;
}

.preset:hover {
    /*background-color: #c42227;*/
}

.preset img {
    width:  50%;
    height: 100px;
    object-fit: cover;
}
.preset p {
    color: white;
    font-size: 20px;
}

.social-media-icons {
    margin: auto;
    width: 100%;
}
.social-media-icons a {
    margin: 0.5em;
    cursor:pointer;
}
.social-media-icons img {
    max-width: 32px;
}

.msuite-watermark {
    position: absolute;
    left: 10px;
    top: 10px;
    max-width: 12em;
    z-index: 100000;
}

.checkbox {
    display: flex;
    flex-direction: row;
    background-color: black;
    cursor:pointer;
    justify-content: space-between;
}
.checkbox p {
    margin-left: 0.5em;
    font-weight: bolder;
}



.checkbox img {
    display: block;
    max-width:115px;
    max-height:42px;
    margin-left: 0.5em;
}
.checkbox p {

}

.ignore-input {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.state-control-icons {
    background-color: gray;
    margin-left: 1em;
    position: fixed;
    bottom: 0px;
    left: 200px;
}
.state-controls-icons a {
    margin-left: 1em;
    cursor: pointer;
}
.state-control-icons img {
    max-width: 32px;
}

.loading-spinner {
    background-color: white;
    z-index: 999999;
    width: 100%;
    height: 100%;
    position: fixed;
}

.msuite-logo-loading {
    max-height: 50%;
    max-width: 50%;
}
.outer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.middle {
    display: table-cell;
    vertical-align: middle;
}

.inner {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    /*whatever width you want*/
}
@import url('https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap');

*,
*::after,
*::before {
    box-sizing: border-box;
}

:root {
    font-size: 20px;
}

::selection {
    background: #2ddab8;
    color: white;
}

body {
    margin: 0;
    padding: 0;
    background: #0c0f13;
    overflow: hidden;
    font-family: 'Sulphur Point', sans-serif;
    color: #8b8b8b;
    font-size: 0.9rem;
}

a {
    color: #545454;
    text-decoration: none;
}

a:focus,
a:hover {
    color: #8b8b8b;
}

#root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.loading {
    padding: 10px;
    transform: translate3d(-50%, -50%, 0);
    color: #8b8b8b;
}

.scrollArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
}

.frame {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 1.5rem;
    z-index: 2;
}

.frame__title {
    font-size: inherit;
    font-weight: normal;
    margin: 0;
}

.frame__links {
    margin: 1rem 0;
}

.frame__link:not(:last-child) {
    margin-right: 1rem;
}

.frame__nav .frame__link:first-child {
    margin-right: 1.5rem;
}

.bottom-left {
    padding: 1.5rem;
    transform: translate3d(0, -100%, 0) !important;
}

.canvas > div {
    z-index: 10000000;
}

.development-modes {
    position: absolute;
    top: 100px;
    left: 30px;
}
.fp-controls-btn {
    font-size: 16px;
}

/* CATEGORY AND AREA BUTTONS  */
.customize-tabs {
    width: 100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.customize-tabs button {
    padding: 4px;
    margin: 0;
    border-left: 2px outset #212121;
    font-size: 12px;
    flex: 0 0 25%;
    border-top:2px outset #212121;
}
.category-tab {
    padding: 0 6px;
    margin: 0;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    border:none;
    align-content: center;
    align-items: center;
    flex: 0 0 50%;
    text-align: center;
    justify-content: center;
    justify-content: space-around;
}
.category-tab:hover {
    transition: 0.5s;
}
.category-tab img {
    max-width:32px;
    max-height:32px;
    margin-right: 0.4em;
    padding: 0;
}
.category-tab p {
    font-size: 1.2em;
    padding: 0;
    font-weight: bolder;
    align-self: center;
    margin-top: 1em;
}
.change-modes {
    display:flex;
    flex-direction: row;
}
.category-img {
    max-width:28px;
    max-height:28px;
}
.preset-img {
    max-width:62px;
    max-height:62px;
}
.category-p {
    font-size: 12px;
    overflow: hidden;
    font-weight: bolder;
}
.utility-bg {
    background-color: rgba(40, 43, 40, 0.6);
    padding: 1em;
    overflow-y: scroll;
    height: 350px;
}
.obj-controls-btn {
    padding: 1em;
}
.btn-with-image {
    border: 1px solid blue;
    cursor:pointer;

    padding: 1em;
}
.btn-with-image img {
    border: 1px solid orange;
}
.btn-with-image p {
    color:white !important;
    font-size: 0.7em;
    border: 1px solid red;
}
#pan-controls img {
    max-width: 2em !important;
    margin: auto !important;
}
#floorplanner-controls {
}
#floorplanner-controls div {
    display: flex;
    flex-direction: row;
}
#floorplanner-controls section {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.7em;
}
#floorplanner-controls button {
    padding: 0.2em !important;
    border-radius: 0 !important;
    flex: 0 0 33.3%;
    display: flex;
    flex-direction: column;
}
#floorplanner-controls button p {
    font-size: 12px;
    margin: 0;
}
#floorplanner-controls button img {
    max-width: 3em !important;

}
.save-load-designs div {
    flex: 0 0 33%;
    cursor: pointer;
}
.preset {
    margin: 0;
}
.preset img {
    padding: 1em;
}
.preset p {
    font-weight: bolder;
    color: gray;
}
.save-load-img {
    max-width: 1em;
}
.show-or-hide-house {
    display:flex; flex-direction: row;
}
.pan-controls-img {
    margin: 0;
    margin-left: 0.1em;
}
.home-msuite-logo {
    max-width: 50%;
}
@media screen and (max-width:961px)  { /* smartphones, iPhone, portrait 480x320 phones */
    ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
    }
    .show-or-hide-house {
        position: fixed;
        bottom: 5px;
        left: 100px;

    }
    .save-load-img {
        max-width: 1em;
    }
    .button_custom {
        padding: 5px;
    }
    .button_custom_selected {
        padding: 5px;
    }
    .home-msuite-logo {
        max-width: 35%;
    }
    .category-img {
        max-width:20px;
        max-height:20px;
    }
    .preset-img {

    }
    .category p {
        font-size: 7px;
    }

    .btn-low {
        font-size: 0.4em !important;
        font-family: Roboto, sans-serif;
        padding: 0.1em !important;
        height: 30px;
        border: none;
        border-right: 1px solid #1b1c1b;
        width: 40px;
    }
    .dev-modes {
        bottom: 0px;
        left: 0px;
    }

    .obj-controls {
        top: 100px;
    }
    .preset {
        cursor:pointer;
        margin: 1em;
        display:flex;
        flex-direction: column;
    }

    .preset:hover {
        /*background-color: #c42227;*/
    }

    .preset img {
        width:  50%;
        height: 50px;
        object-fit: cover;
    }
    .preset p {
        color: white;
        font-size: 20px;
    }
    .customize-tabs {
        width: 100%;
        display:flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    .interface-controls {
        margin: 0 auto;
        text-align: center;
        position: fixed;
        bottom: 0px;
        left: 0px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 1em;
        padding: 1em;
        width: 40em;
        font-size: 7px !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .development-modes {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .title-clean,
    .title-clean-lg{
        text-align: left;
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 7px;
        font-weight: bolder;
        color: white;
        margin-top: 0.75em;
        margin-bottom: 0;
        border-right: 1em;
    }
    .subtitle-clean {
        text-align: left;
        color: gray;
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 6px;
        margin-top: 0.2em;
        margin-bottom: 0.5em;
    }
    #floorplanner-controls {
        display:flex;
        flex-direction: row;
    }
    #floorplanner-controls div {
        display:flex;
        flex-direction: row;
    }
    .fp-controls-btn {
        font-size: 7px;
    }
    .fp-controls-btn img {
        max-width: 12px;
    }
    #wall-measurements {
        background-color: white;
        padding: 1em;
        position: fixed;
        bottom: 0px;
        margin: auto;
        left: 300px;
        width: 15em;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .utility-bg {
        height: 170px;
    }
    .category-tab {
        padding: 0 2px;
        margin: 0;
        font-size: 7px;
    }
    .category-tab img {
        max-width:24px;
        max-height:24px;
        margin-right: 0.2em;
        padding: 0;
    }
    .category-tab p {
        font-size: 1.2em;
        padding: 0;
        font-weight: bolder;
        align-self: center;
        margin-top: 1em;
    }
    #floorplanner-controls div {
        display: flex;
        flex-direction: row;
    }
    #floorplanner-controls section {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.2em;
        margin-right: 1em;
    }
    #floorplanner-controls button {
        padding: 0.1em !important;
        border-radius: 0 !important;
        flex: 0 0 33.3%;
        display: flex;
        flex-direction: column;
    }
    #floorplanner-controls button p {
        font-size: 7px;
        margin: 0;
    }
    #floorplanner-controls button img {
        max-width: 1em !important;

    }
    .item-controls {
        position: absolute;
        left: 10px;
        top: 90px;
        padding: 0.5em;
    }
    .obj-controls {
        display:flex;
        flex-direction: row;
        margin-top: 0;
    }
    .obj-controls button {
        flex: 0 0 33.3% !important;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding: 0;
        height: 40px;
    }

    .obj-controls button img {
        max-width: 8px;
        padding: 0;
        margin-top: 2em;

    }
    .obj-controls button p {
        font-size: 7px;
        align-self: center;
        padding: 0;


    }



    .rotate-controls {
        display:flex;
        flex-direction: row;
        margin-top: 0;
    }
    .rotate-controls button {
        flex: 0 0 50% !important;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding: 0;
        height: 40px;
    }

    .rotate-controls button img {
        max-width: 8px;
        padding: 0;
        margin-top: 3em;

    }
    .rotate-controls button p {
        font-size: 7px;
        align-self: center;
        padding: 0;
    }

    .development-modes {
        left :0;
        top: 0;
    }

}

@media screen and (min-width: 53em) {
    body {
        overflow: hidden;
    }
    .frame {
        display: grid;
        height: 100vh;
        padding: 2rem 3.7rem;
        pointer-events: none;
        align-content: space-between;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto auto;
        grid-template-areas: 'title links'
        '... ...'
        '... nav';
    }
    .frame__title {
        grid-area: title;
    }
    .frame__links {
        grid-area: links;
        justify-self: end;
        margin: 0;
    }
    .frame__link {
        pointer-events: auto;
    }
    .frame__nav {
        grid-area: nav;
        justify-self: end;
    }
    .bottom-left {
        padding: 0 0 2rem 3.7rem;
    }


}

.cta-corner {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 99999999;
}



.bg-transparent-gray {
    background-color: rgba(40, 43, 40, 0.6);
    border-radius: 1em;
}

.privacy-policy {
    color: white !important;
    height: 700px;
    font-size: 12px !important;
}

.privacy-policy h1 {
    color: white !important;
}

.display-lighting {
    display:flex;
    flex-direction: row;
}
.input-small {
    width: 100%;
    border: 1px solid black;
}
.input-label-small {
    color: white;
    margin: 0;
    font-size: 12px;
}

#envate-ad {
    z-index: 99999999999;
    position: fixed;
    width: 500px;
    height: 200px;
    top: 12%;
    left: 50%;
    margin-top: -100px; /* Negative half of height. */
    margin-left: -250px; /* Negative half of width. */

}
#envate-ad img {
    max-width: 400px;
}

#envate-ad button {
    position: absolute;
    top: 0;
    right: 50px;
}

.faded-out {
    opacity: 1;
    display:none;
    z-index: -999;
}

.btn-close {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background-color: gray;
}
.img-is-selected {
    border: 2px solid red;

}
.btn-font-styling {
    font-weight: bolder;
    font-family: Roboto, sans-serif;
}
