.nav-links {
    text-align: right;
    padding-right: 1em;
    padding-top: 1em;
    color:gray !important;
}
.nav-links span {
    cursor: pointer;
}
.nav-links span:hover {
    color: grey;
}
.nav-links a {
    margin-right: 1em;
    color:black;
}


.nav-home {
    width: 100%;
    height: 65px;
    position: fixed;
    white-space: nowrap;
    overflow: hidden;
    z-index: 9999999;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    border-radius:0 !important;
}

.nav-footer {
    background-color: whitesmoke;
    width: 100%;

    display: flex;
    justify-content: space-between;
    bottom: 0;
    position: fixed;
    white-space: nowrap;
    overflow: hidden;
    z-index: 9999999;
    height: 50px;
    font-family: Roboto, sans-serif;
    -webkit-box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.75);
}

.nav-footer p {
    color: gray;
    font-weight: 400;
    margin: 1em;
}

.nav-footer img {
    max-height: 3em;
}

.cta-text {
    color: black;
    font-weight: lighter;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.2em;
}

.nav-home-logo {
    max-width: 120px;
    min-width: 120px;
    left: 0;
    bottom: -5px;
    position: absolute;
    margin: 20px;
    cursor: pointer;
}

.n-logo {
    width: 25%;
    margin-top: 5%;
}
.nav-main-links a{
    color:white;
    font-weight: 400;
}
.nav-main-links a:hover {
    color: #C42227;
}

.nav-main-links button {
    padding: 0.7em;
    font-size: 0.75em;
}
@media screen and (max-width: 480px) {
    .nav-home-logo {
        max-width: 90px;
        min-width: 90px;
        left: 0;
        bottom: -5px;
        position: absolute;
        margin: 20px;
        cursor: pointer;
    }
    .cta-text {
        font-size: 0.6em;
    }
    .nav-footer p {

    }
    .nav-footer img {
        width: 16px;
    }
    .nav-footer {
        font-size: 9px;
        overflow: hidden;
    }
    .nav-main-links button {
        padding: 0.7em;
        margin-left: 0.2em;
        font-size: 1em;
    }
    .nav-links {
        text-align: right;
        padding-right: 0em;
        padding-top: 1em;
        color:gray !important;
    }
    .nav-links span {
        cursor: pointer;
    }
    .nav-links span:hover {
        color: grey;
    }
    .nav-links a {
        margin-right: 1em;
        color:white;
        font-size: 0.5em;
    }


    .nav-home {
        width: 100%;
        height: 65px;
        position: fixed;
        white-space: nowrap;
        overflow: hidden;
        z-index: 9999999;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    }

}
