
.panel-container {
    width: 23%; /* 0 width - change this with JavaScript */
    z-index: 5; /* Stay on top */
    display: block;
    margin-left: 1em;
    right: 10px;
    top: 10px;
    position: absolute;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}

.panel-container_mobile {
    width: 20.5%; /* 0 width - change this with JavaScript */
    z-index: 5; /* Stay on top */
    display: block;
    margin-left: 1em;
    height: 100%;
    right: 0;
    position: absolute;
}

#side-panel {
    overflow: hidden;
    z-index: 100;

}

#tabs {
    display: flex;
    flex-direction: row;
    z-index: 99999;
    color: black;
    text-align: center;
    width: 100%;
    font-weight: bolder;
}

.tab {
    padding-top: 0.5em;
    width: 33%;
    color: gray;
    border-right: 1px solid #1b1c1b;
    flex: 0 0 33.3%;

}

.tab-selected {
    padding-top: 0.5em;
    width: 33%;
    color: whitesmoke;
    border-right: 1px solid #1b1c1b;

}

.tab p,
.tab-selected p {
    font-size: 14px;
}
#tabs a {
    cursor: pointer;
    color: white;
    font-weight: 900;
}
#tabs span {
    cursor:pointer;
}
#tabs span:hover {

}

#tabs a:last-child {
    position: absolute;
    justify-self: flex-end;
    bottom: 20px;
}

#tabs_mobile {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: -70px;
    z-index: 99999;
    color: black;
    text-align: center;
    padding: 1em;

}
#side-panel-title {
    color: white;
    width: 100%;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
    font-weight: bold;
    background-color: #1b1c1b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#282b28), to(#1b1c1b));
    background-image: -webkit-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -moz-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -ms-linear-gradient(top, #282b28, #1b1c1b);
    background-image: -o-linear-gradient(top, #282b28, #1b1c1b);
    background-image: linear-gradient(to bottom, #282b28, #1b1c1b);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#282b28, endColorstr=#1b1c1b);
}
#side-panel-title h2 {
    font-size: 32px;

}

.categories-wrapper {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: hidden;
}
.category {
    padding: 0.4em 1.5em;
    width: 33.33333%;
    color:gray;
    font-size: 0.75em;
    border-right: 1px solid #1b1c1b;
    border-bottom: 1px solid #1b1c1b;
    background-color: rgba(40, 43, 40, 0.8);
}
.category-selected {

}

.category img {
    width: 26px;
    height: 26px;
}
.category:hover {
    background-color: #1b1c1b;
    cursor: pointer;
}

.catalogue-section {
    background-color: rgba(40, 43, 40, 0.8);
}
.tab-img {
    max-width: 26px;
}
@media screen and (max-width:961px)  { /* smartphones, iPhone, portrait 480x320 phones */
    .tab-img {
        max-width: 22px;
    }
    .tab,
    .tab-selected  {
        padding-top: 0em;
        height: 2.2rem;
    }

    .tab p,
    .tab-selected p {
        font-size: 9px;
    }
    .panel-container {
        width: 30%; /* 0 width - change this with JavaScript */
        margin-right: 0em;
        right: 0;
        top: 0;
    }
    .category {
        padding: 0.2em 0.5em;
        width: 33.33333%;
        color:gray;
        font-size: 0.75em;
        border-right: 1px solid #1b1c1b;
        border-bottom: 1px solid #1b1c1b;
        background-color: rgba(40, 43, 40, 0.8);
    }
}
